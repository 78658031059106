import React from "react";
import "./App.css";
import jokes from "./jokes";

function App() {
  // Get a random joke from the array
  const randomJoke = jokes[Math.floor(Math.random() * jokes.length)];

  return (
    <div className="App">
      <header className="App-header">
        {/* <h1>Dad Joke of the Day</h1> */}
        <p>{randomJoke}</p>
      </header>
    </div>
  );
}

export default App;
