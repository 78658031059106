const jokes = [
  "Why did the scarecrow win an award? Because he was outstanding in his field.",
  "Did you hear about the mathematician who’s afraid of negative numbers? He’ll stop at nothing to avoid them.",
  "Why did the bicycle stand up by itself? It was two-tired.",
  "What did one ocean say to the other ocean? Nothing, they just waved.",
  "Why did the tomato turn red? Because it saw the salad dressing.",
  "How do you make a tissue dance? You put a little boogie in it.",
  "What do you call fake spaghetti? An impasta.",
  "I used to play piano by ear, but now I use my hands.",
  "What do you call a fish wearing a bowtie? Sofishticated.",
  "I told my computer I needed a break, and now it won't stop giving me ads for vacations.",
  "How do you organize a space party? You planet.",
  "What do you get when you cross a snowman with a vampire? Frostbite.",
  "Why did Beethoven get rid of his chickens? All they ever said was, 'Bach, Bach, Bach'.",
  "Why did the golfer change his pants? Because he got a hole in one.",
  "Why don’t scientists trust atoms? Because they make up everything.",
  "How do you make holy water? You boil the hell out of it.",
  "Why don't skeletons fight each other? They don't have the guts.",
  "Did you hear about the guy who invented Lifesavers? He made a mint.",
  "I would tell you a joke about an elevator, but it’s an uplifting experience.",
  "What do you call a factory that makes good products? A satisfactory.",
  "Why did the math book look sad? Because of all its problems.",
  "Why did the scarecrow win an award? Because he was outstanding in his field.",
  "How does a snowman get around? By riding an ‘icicle’.",
  "Why did the bicycle fall over? Because it was two-tired.",
  "I told my wife she was drawing her eyebrows too high. She looked surprised.",
  "What did one wall say to the other wall? I’ll meet you at the corner.",
  "Why was the math book sad? Because it had too many problems.",
  "Why can't you trust an atom? Because they make up everything.",
  "What do you call a bear with no teeth? A gummy bear.",
  "How do you catch a squirrel? Climb a tree and act like a nut.",
  "Why don't scientists trust atoms? Because they make up everything.",
  "Why did the chicken join a band? Because it had the drumsticks.",
  "How does a penguin build its house? Igloos it together.",
  "Why did the golfer wear two pairs of pants? In case he got a hole in one.",
  "Why did the scarecrow win an award? Because he was outstanding in his field.",
  "What did one plate say to another plate? Tonight, dinner's on me.",
  "Why did the can crusher quit his job? Because it was soda pressing.",
  "Why did the scarecrow win an award? He was outstanding in his field.",
  "Why did the bicycle stand up by itself? It was two-tired.",
  "What do you call a snowman with a six-pack? An abdominal snowman.",
  "Why did the tomato turn red? Because it saw the salad dressing.",
  "Why did the bicycle fall over? Because it was two-tired.",
  "Why did the chicken join the band? Because it had the drumstick.",
  "Why couldn’t the bicycle stand up by itself? It was two-tired.",
  "Why did the can crusher quit his job? Because it was soda pressing.",
  "Why did the scarecrow win an award? Because he was outstanding in his field.",
  "Why did the bicycle stand up by itself? It was two-tired.",
  "Why did the chicken join a band? Because it had the drumstick.",
  "How do you make holy water? You boil the hell out of it.",
  "Why did the golfer bring two pairs of pants? In case he got a hole in one.",
  "What do you call cheese that's not yours? Nacho cheese.",
  "Why couldn't the bicycle find its way home? It lost its bearings.",
  "Why did the scarecrow get promoted? He was outstanding in his field.",
  "Why do bees have sticky hair? Because they always use honeycombs.",
  "I'm reading a book on anti-gravity. It's impossible to put down.",
  "What did the lemon say to the lime? Sour you doing?",
  "Why was the computer cold? It left its Windows open.",
  "What did the left eye say to the right eye? Between you and me, something smells.",
  "What do you call a bear stuck in the rain? A drizzly bear.",
  "Why was the belt arrested? For holding up a pair of pants.",
  "What do you call a bear with no ears? B.",
  "Why did the golfer bring an extra pair of pants? In case he got a hole in one.",
  "What do you call a pile of cats? A meowtain.",
  "What do you call an alligator in a vest? An investigator.",
  "What did one hat say to the other? You stay here, I’ll go on ahead.",
  "How do cows stay up to date with current events? They read the moos-paper.",
  "Why don't skeletons go to prom? They have no body to dance with.",
  "What do you call a dog magician? A labracadabrador.",
  "How do you catch a squirrel? Climb a tree and act like a nut.",
  "Why did the scarecrow win an award? For being outstanding in his field.",
  "Why was the computer stressed? It had too many tabs open.",
  "Why did the tomato turn red? Because it saw the salad dressing.",
  "Why did the scarecrow become a banker? He was outstanding in his field.",
  "Why did the golfer always bring two pairs of pants? In case he got a hole in one.",
  "Why did the banana go to the doctor? It wasn’t peeling well.",
  "What do you call a sleeping bull? A bulldozer.",
  "What do you call a fish with no eyes? Fsh.",
  "Why did the golfer bring two pairs of pants? In case he got a hole in one.",
  "Why do chicken coops only have two doors? Because if they had four, they’d be a chicken sedan.",
  "Why did the scarecrow win an award? He was outstanding in his field.",
  "Why do seagulls fly over the sea? Because if they flew over the bay, they’d be bagels.",
  "How do you organize a space party? You planet.",
  "Why did the scarecrow become a lawyer? He was outstanding in his field.",
  "What do you call a man with no nose and no body? Nobody nose.",
  "Why did the bicycle fall over? It was two-tired.",
  "Why did the golfer bring two pairs of pants? In case he got a hole in one.",
  "Why did the tomato turn red? Because it saw the salad dressing.",
  "Why was the belt sent to jail? For holding up a pair of pants.",
  "Why did the bicycle fall over? It was two-tired.",
  "How do you make a tissue dance? You put a little boogie in it.",
  "Why did the tomato turn red? Because it saw the salad dressing.",
  "Why did the scarecrow win an award? Because he was outstanding in his field.",
  "What do you call a fish with a tie? So-fish-ticated.",
  "Why did the golfer bring two pairs of pants? In case he got a hole in one.",
  "Why was the math book sad? Because it had too many problems.",
  "Why did the tomato turn red? Because it saw the salad dressing.",
  "What do you call a bear with no teeth? A gummy bear.",
  "How do you catch a squirrel? Climb a tree and act like a nut.",
  "What do you call a pile of kittens? A meowtain.",
  "Why did the scarecrow get promoted? Because he was outstanding in his field.",
  "What’s brown and sticky? A stick.",
  "Why did the orange stop rolling down the hill? It ran out of juice.",
  "What do you call a boomerang that won't come back? A stick.",
  "Did you hear about the kidnapping at the playground? They woke up.",
  "How does the ocean say hi? It waves.",
  "How does a snowman get to work? By icicle.",
  "What’s a vampire’s favorite fruit? A blood orange.",
  "Why was the belt arrested? For holding up pants!",
  "Did you hear about the restaurant on the moon? Great food, no atmosphere.",
  "Why did the scarecrow win an award? Because he was outstanding in his field.",
  "What do you call a bear with no teeth? A gummy bear.",
  "Why did the tomato turn red? Because it saw the salad dressing.",
  "Did you hear about the kidnapping at the playground? They woke up.",
  "What did the policeman say to his belly button? You’re under a vest.",
  "What do you call a fish with a bowtie? Sofishticated.",
  "Did you hear about the circus fire? It was in-tents.",
  "Why do ducks have feathers? To cover their butt-quack.",
  "What do you call a shoe made of a banana? A slipper.",
  "Why did the tomato turn red? Because it saw the salad dressing.",
  "How do you make a tissue dance? You put a little boogie in it.",
  "Why don’t eggs tell secrets? They might crack up.",
  "What’s the difference between a poorly dressed man on a unicycle and a well-dressed man on a bike? Attire.",
  "How do you catch a squirrel? Climb a tree and act like a nut.",
  "Why couldn't the bicycle stand up by itself? It was two-tired.",
  "How do you organize a space party? You planet.",
  "Why did the scarecrow win an award? Because he was outstanding in his field.",
  "Why did the chicken join a band? Because it had the drumstick.",
  "Why did the tomato turn red? Because it saw the salad dressing.",
  "What do you call an elephant that doesn’t matter? Irrelephant.",
  "What do you call a snowman with a six-pack? An abdominal snowman.",
  "How do you make holy water? You boil the hell out of it.",
  "What do you call a shoe made of a banana? A slipper.",
  "Why don't skeletons go on vacation? They're afraid of the sun.",
  "Why don't scientists trust atoms? Because they make up everything.",
  "Why did the golfer bring two pairs of pants? In case he got a hole in one.",
  "What did one plate say to another plate? Dinner's on me.",
  "Why did the bicycle fall over? It was two-tired.",
  "How do you make a tissue dance? You put a little boogie in it.",
  "Did you hear about the kidnapping at the playground? They woke up.",
  "Why did the scarecrow win an award? Because he was outstanding in his field.",
  "How do you make a tissue dance? You put a little boogie in it.",
  "Why did the tomato turn red? Because it saw the salad dressing.",
  "What did one wall say to the other wall? I'll meet you at the corner.",
  "Why did the golfer bring two pairs of pants? In case he got a hole in one.",
  "Why did the chicken join the band? Because it had the drumstick.",
  "How do you make holy water? You boil the hell out of it.",
  "What do you call a bear with no teeth? A gummy bear.",
  "How do you catch a squirrel? Climb a tree and act like a nut.",
  "Why did the math teacher sit on the radiator? Because she wanted to be a hot teacher.",
  "What does a bee use to brush its hair? A honeycomb.",
  "Did you hear about the guy who invented Lifesavers? He made a mint.",
  "I wouldn't buy anything with velcro. It's a total rip-off.",
  "Why was Cinderella thrown off the soccer team? She kept running from the ball.",
  "Why don’t scientists trust atoms? Because they make up everything.",
  "I only know 25 letters of the alphabet. I don’t know y.",
  "Why was the math book sad? It had too many problems.",
  "Why did the scarecrow win an award? He was outstanding in his field.",
  "Did you hear about the claustrophobic astronaut? He just needed a little space.",
  "How does a penguin build its house? Igloos it together.",
  "Why did the tomato turn red? Because it saw the salad dressing.",
  "Why did the bicycle fall over? Because it was two-tired.",
  "What do you call fake spaghetti? An impasta.",
  "How does a snowman get around? He rides an ‘icicle’.",
  "Why couldn't the bicycle find its way home? It lost its bearings.",
  "What do you call a fish wearing a bowtie? Sofishticated.",
  "Why did the scarecrow win an award? Because he was outstanding in his field.",
  "How do you tell if a vampire is sick? By how much he is coffin.",
  "Why did the golfer bring two pairs of pants? In case he got a hole in one.",
  "How do you organize a space party? You planet.",
  "Why did the tomato turn red? Because it saw the salad dressing.",
  "I'm friends with all electricians. We have good current connections.",
  "Why was the coach yelling at the vending machine? He wanted his quarterback.",
  "What do you call a bear caught in the rain? A drizzly bear.",
  "How do you make a tissue dance? You put a little boogie in it.",
  "Did you hear about the kidnapping at the playground? They woke up.",
  "Why did the tomato turn red? Because it saw the salad dressing.",
  "I told my wife she was drawing her eyebrows too high. She looked surprised.",
  "I used to play piano by ear, but now I use my hands.",
  "How do you organize a space party? You planet.",
  "Why did the scarecrow win an award? He was outstanding in his field.",
  "Why did the golfer bring two pairs of pants? In case he got a hole in one.",
  "Why was the coach yelling at the computer? He wanted to get his byte back.",
  "What did one plate say to another? Tonight, dinner’s on me.",
  "Why did the bicycle fall over? It was two-tired.",
  "How do you make a tissue dance? You put a little boogie in it.",
  "Why did the scarecrow win an award? Because he was outstanding in his field.",
  "How do you catch a squirrel? Climb up in a tree and act nutty.",
  "Why don't skeletons fight each other? They don't have the guts.",
  "Why did the math book look sad? Because it had too many problems.",
  "Why did the golfer bring two pairs of pants? In case he got a hole in one.",
  "How do you make a tissue dance? You put a little boogie in it.",
  "Why was the math teacher suspicious of the music notes? Because they were up to treble.",
  "Why did the golfer bring two pairs of pants? In case he got a hole in one.",
  "How do you throw a space party? You planet.",
  "Why did the scarecrow win an award? Because he was outstanding in his field.",
  "How do you make holy water? You boil the hell out of it.",
  "What do you call a bear with no teeth? A gummy bear.",
  "Why don't oysters donate to charity? Because they're shellfish.",
  "How do you make an octopus laugh? With ten-tickles.",
  "What did the big flower say to the little flower? Hi, bud!",
  "What's the best way to burn 1,000 calories? Leave the pizza in the oven.",
  "How do you organize a fantastic space party? You planet.",
  "Why did the computer keep freezing? It had too many windows open.",
  "Why don’t seagulls fly over the bay? Because then they’d be bagels.",
  "What did one hat say to the other hat? You stay here, I'll go on ahead.",
  "What do you call a parade of rabbits hopping backward? A receding hare-line.",
  "Why did the picture go to jail? It was framed.",
  "What did the fisherman say to the magician? Pick a cod, any cod.",
  "Why did the golfer bring two pairs of pants? In case he got a hole in one.",
  "Why was the math teacher suspicious of the numbers? Because they didn’t add up.",
  "Did you hear about the kidnapping at the playground? They woke up.",
  "Why did the scarecrow win an award? Because he was outstanding in his field.",
  "What do you call an illegally parked frog? Toad.",
  "What do you get when you cross a snowman and a vampire? Frostbite.",
  "Why couldn’t the bicycle stand up by itself? It was two-tired.",
  "Why did the scarecrow win an award? Because he was outstanding in his field.",
  "Why did the tomato turn red? Because it saw the salad dressing.",
  "Did you hear about the kidnapping at the playground? They woke up.",
  "How do you organize a space party? You planet.",
  "Why was the math book sad? Because it had too many problems.",
  "How does a snowman get around? He rides an ‘icicle’.",
  "Why was the math teacher suspicious of the music notes? Because they were up to treble.",
  "Why did the golfer bring two pairs of pants? In case he got a hole in one.",
  "What do you call a bear with no teeth? A gummy bear.",
  "How do you make a tissue dance? You put a little boogie in it.",
  "Why did the scarecrow win an award? Because he was outstanding in his field.",
  "Why was Cinderella bad at basketball? Because she ran away from the ball.",
  "Why did the melon jump into the wedding? Because it cantaloupe.",
  "How does a lemon ask for a hug? Can I squeeze you?",
  "Why did the golfer bring two pairs of pants? In case he got a hole in one.",
  "Why did the bicycle fall over? It was two-tired.",
  "What do you call a snowman with a six-pack? An abdominal snowman.",
  "How do you make holy water? You boil the hell out of it.",
  "Why don’t scientists trust atoms? Because they make up everything.",
  "What’s a vampire’s least favorite room? The living room.",
  "Why did the tomato turn red? Because it saw the salad dressing.",
  "How do you make a tissue dance? You put a little boogie in it.",
  "Why did the scarecrow win an award? Because he was outstanding in his field.",
  "How do you catch a squirrel? Climb up in a tree and act nutty.",
  "Why did the golfer bring two pairs of pants? In case he got a hole in one.",
  "Why did the tomato turn red? Because it saw the salad dressing.",
  "What did one wall say to the other wall? I'll meet you at the corner.",
  "How do you throw a space party? You planet.",
  "Why don’t skeletons fight each other? They don’t have the guts.",
  "Why did the golfer bring two pairs of pants? In case he got a hole in one.",
  "How do you make a tissue dance? You put a little boogie in it.",
  "What’s a vampire’s least favorite room? The living room.",
  "Why did the golfer wear two pairs of pants? In case he got a hole in one.",
  "What do you call a fish with a tie? So-fish-ticated.",
  "Why did the scarecrow get promoted? Because he was outstanding in his field.",
  "What do you call a dog that can do magic tricks? A labracadabrador.",
  "Why do bees have sticky hair? Because they always use honeycombs.",
  "Did you hear about the kidnapping at the playground? They woke up.",
  "Why did the can crusher quit his job? Because it was soda pressing.",
  "I told my wife I felt like a deck of cards. She said I should go see the doctor in case I'm not playing with a full deck.",
  "What did the janitor say when he jumped out of the closet? Supplies!",
  "Why don’t skeletons use cell phones? Because they have no-body to call.",
  "Why did the chicken join the band? Because it had the drumsticks.",
  "What did one elevator say to the other elevator? I think I’m coming down with something.",
  "Why did the bicycle fall over? It was two-tired.",
  "What do you call a bear stuck in the rain? A drizzly bear.",
  "Why did the math book look sad? It had too many problems.",
  "Why can't you give Elsa a balloon? Because she will let it go.",
  "What do you call a shoe made out of a banana? A slipper.",
  "Why did the scarecrow win an award? He was outstanding in his field.",
  "Did you hear about the kidnapping at the playground? They woke up.",
  "Why did the tomato turn red? It saw the salad dressing.",
  "I know a lot of jokes about retired people, but none of them work.",
  "Why did the scarecrow win an award? He was outstanding in his field.",
  "Why did the chicken sit on an egg? It didn’t want to lay down.",
  "What do you call a bear that’s stuck in the rain? A drizzly bear.",
  "What do you call a bear with no teeth? A gummy bear.",
  "Why did the bicycle fall over? It was two-tired.",
  "How do you organize a space party? You planet.",
  "Why did the golfer bring two pairs of pants? In case he got a hole in one.",
  "How does a snowman get around? He rides an ‘icicle’.",
  "Why was the math teacher suspicious of the music notes? Because they were up to treble.",
  "Why did the golfer bring two pairs of pants? In case he got a hole in one.",
  "What do you call a bear with no teeth? A gummy bear.",
  "How do you make a tissue dance? You put a little boogie in it.",
  "Why did the scarecrow win an award? Because he was outstanding in his field.",
  "Why did the chicken cross the playground? To get to the other slide.",
  "Why was the belt arrested? For holding up a pair of pants.",
  "Why did the golfer bring two pairs of pants? In case he got a hole in one.",
  "Why did the tomato turn red? Because it saw the salad dressing.",
  "How do you make a tissue dance? You put a little boogie in it.",
  "Why did the scarecrow win an award? Because he was outstanding in his field.",
  "How do you catch a squirrel? Climb up in a tree and act nutty.",
  "Why was the student's report card wet? Because his grades were below sea level.",
  "Why did the tomato turn red? Because it saw the salad dressing.",
  "Why don’t scientists trust atoms? Because they make up everything.",
  "What do you call a bear with no teeth? A gummy bear.",
  "Why couldn’t the leopard play hide and seek? Because he was always spotted.",
  "How do you throw a space party? You planet.",
  "Why did the golfer bring two pairs of pants? In case he got a hole in one.",
  "How do you make a tissue dance? You put a little boogie in it.",
  "What did one wall say to the other? I'll meet you at the corner.",
  "How did the barber win the race? He took a shortcut.",
  "Why did the math book look sad? Too many problems.",
  "Why did the stadium get hot after the game? All of the fans left.",
  "How do you fix a broken pumpkin? With a pumpkin patch.",
  "Why did the teacher go to the beach? To test the waters.",
  "Why did the scarecrow keep getting promoted? He was outstanding in his field.",
  "How does a duck buy lipstick? She just puts it on her bill.",
  "How did the hipster burn his mouth? He drank his coffee before it was cool.",
  "Why did the golfer change his pants? He got a hole in one.",
  "What did the traffic light say to the car? Don’t look! I’m about to change.",
  "Why was the computer cold? It left its Windows open.",
  "Why do skeletons have low self-esteem? They have no body to love.",
  "What did the left eye say to the right eye? Between you and me, something smells.",
  "Why was the math book stressed? It had too many problems.",
  "Why do bees have sticky hair? Because they use honeycombs.",
  "Why don't scientists trust atoms anymore? Because they make up everything.",
  "Why did the scarecrow win an award? Because he was outstanding in his field.",
  "Did you hear about the kidnapping in the park? They woke up.",
  "How do you make holy water? You boil the hell out of it.",
  "What do you call an alligator detective? An investi-gator.",
  "Did you hear about the kidnapping at the library? They were after the over-due books.",
  "How do you catch a squirrel? Act like a nut!",
  "What did the tree say to the wind? Leaf me alone!",
  "Why do fish live in salt water? Because pepper water makes them sneeze!",
  "What do you call a factory that makes good products? A satisfactory.",
  "Why was Cinderella thrown off the basketball team? She ran away from the ball.",
  "Why was the belt arrested? For holding up pants.",
  "How do you organize a space party? You planet.",
  "Why did the golfer wear two shirts? In case he got a hole in one.",
  "Why did the scarecrow win the competition? He was outstanding in his field.",
  "What did one plate say to the other plate? Dinner's on me.",
  "Why did the bicycle fall over? Because it was two-tired.",
  "Why did the tomato turn red? It saw the salad dressing.",
  "What do you call cheese that isn't yours? Nacho cheese.",
  "Why was the math book sad? Because of all its problems.",
  "Why do we never tell secrets on a farm? Because potatoes have eyes, the corn has ears, and the beans stalk.",
  "What do you call a man with a rubber toe? Roberto.",
  "What did one ocean say to the other ocean? Nothing, they just waved.",
  "What do you call a bear with no teeth? A gummy bear.",
  "What do you call a bear stuck in the rain? A drizzly bear.",
  "Why did the tomato turn red? Because it saw the salad dressing.",
  "Why did the scarecrow win an award? Because he was outstanding in his field.",
  "What's a vampire's least favorite room? The living room.",
  "How do you make a tissue dance? You put a little boogie in it.",
  "What do you call a bear with no teeth? A gummy bear.",
  "Why did the bicycle fall over? Because it was two-tired.",
  "Why was the math book sad? It had too many problems.",
  "Why don’t scientists trust atoms? Because they make up everything.",
  "Why did the golfer bring two pairs of pants? In case he got a hole in one.",
  "Why did the tomato turn red? Because it saw the salad dressing.",
  "What do you call a fake noodle? An impasta.",
  "Did you hear about the cheese factory explosion? There was nothing left but de-brie.",
  "What do you call a fish that wears a crown? A king fish.",
  "Why did the photo go to school? To get picture perfect.",
  "Why did the computer keep freezing? It had too many windows open.",
  "What do you call a bear with no ears? B.",
  "Why don’t scientists trust atoms? Because they make up everything.",
  "Did you hear about the actor who fell through the floor? It was just a stage he was going through.",
  "Why don't skeletons fight each other? They don't have the guts.",
  "What's the best tool to do math? Multi-pliers.",
  "Why did the school kids eat their homework? Because the teacher said it was a piece of cake.",
  "How do you throw a space party? You planet.",
  "What did one hat say to the other? You stay here, I'll go on ahead.",
  "Why did the coffee file a police report? It got mugged.",
  "How do you organize a space party? You planet.",
  "What kind of shoes does a ninja wear? Sneak-ers.",
  "Why did the golfer bring two pairs of pants? In case he got a hole in one.",
  "Why did the scarecrow win an award? He was outstanding in his field.",
  "How does a snowman get around? On an icicle.",
  "Why did the tomato turn red? Because it saw the salad dressing.",
  "Why was the broom late? It overswept.",
  "What do you call a bear caught in the rain? A drizzly bear.",
  "Why did the bicycle fall over? It was two-tired.",
  "How do you make a tissue dance? Put a little boogie in it.",
  "Why did the chicken join a band? Because it had the drumsticks.",
  "What do you call a sleeping bull? A bulldozer.",
  "Why was the computer stressed on its birthday? Too many bites.",
  "Why do seagulls fly over the sea? If they flew over the bay, they’d be bagels.",
  "What do you call a bear with no teeth? A gummy bear.",
  "How do you organize a space party? You planet.",
  "Why don't skeletons fight each other? They don't have the guts.",
  "What do you call a bear that’s stuck in the rain? A drizzly bear.",
  "Why did the bicycle fall over? It was two-tired.",
  "How do you organize a space party? You planet.",
  "Why did the golfer bring two pairs of pants? In case he got a hole in one.",
  "How do you make a tissue dance? You put a little boogie in it.",
  "Why did the scarecrow win an award? He was outstanding in his field.",
  "How do you catch a squirrel? Climb up in a tree and act nutty.",
  "Why was the student's report card wet? Because his grades were below sea level.",
  "Why did the tomato turn red? Because it saw the salad dressing.",
  "Why don’t scientists trust atoms? Because they make up everything.",
  "What do you call a bear with no teeth? A gummy bear.",
  "Why couldn’t the leopard play hide and seek? Because he was always spotted.",
  "How do you throw a space party? You planet.",
  "Why did the golfer bring two pairs of pants? In case he got a hole in one.",
  "How do you make a tissue dance? You put a little boogie in it.",
  "What did one wall say to the other? I'll meet you at the corner.",
  "Why don't scientists trust atoms? Because they make up everything.",
  "Did you hear about the mathematician who's afraid of negative numbers? He'll stop at nothing to avoid them.",
  "Why did the student eat his homework? The teacher said it was a piece of cake.",
  "Why did the golfer change his socks? He got a hole in one.",
  "Why don't eggs tell secrets? They might crack up.",
  "What's the best way to watch a fly-fishing tournament? Live stream.",
  "Did you hear about the claustrophobic astronaut? He just needed a little space.",
  "Why did the scarecrow win an award? Because he was outstanding in his field.",
  "What do you call a snowman with a six-pack? An abdominal snowman.",
  "Why did the bicycle fall over? It was two-tired.",
  "How do you turn a pirate furious? Take away the 'p'.",
  "Why did the tomato turn red? Because it saw the salad dressing.",
  "What do you call a parade of rabbits hopping backward? A receding hare-line.",
  "Why did the math book look sad? Because it had too many problems.",
  "Did you hear about the kidnapping at the playground? They woke up.",
  "Why did the scarecrow win an award? Because he was outstanding in his field.",
  "What do you call an elephant that doesn't matter? Irrelephant.",
  "Why did the golfer bring two pairs of pants? In case he got a hole in one.",
  "Why did the bicycle fall over? It was two-tired.",
  "How do you catch a squirrel? Climb a tree and act like a nut.",
  "How do you organize a space party? You planet.",
  "Did you hear about the cheese factory explosion? De-brie was everywhere.",
  "Why don't skeletons fight each other? They don't have the guts.",
  "What do you call a bear stuck in the rain? A drizzly bear.",
  "Why did the scarecrow win an award? He was outstanding in his field.",
  "Why did the tomato turn red? Because it saw the salad dressing.",
  "What do you call a factory that makes okay products? A satisfactory.",
  "Why did the math book look sad? It had too many problems.",
  "How do you make a tissue dance? You put a little boogie in it.",
  "Why did the bicycle fall over? It was two-tired.",
  "Why don't eggs tell each other secrets? Because they might crack up.",
  "Why don't skeletons play music in church? They have no organs.",
  "Why couldn't the bicycle stand up by itself? It was two-tired.",
  "How does a penguin build its house? Igloos it together.",
  "Why did the scarecrow win an award? Because he was outstanding in his field.",
  "Why did the tomato turn red? Because it saw the salad dressing.",
  "What's brown and sticky? A stick.",
  "Why did the golfer bring two pairs of pants? In case he got a hole in one.",
  "What did one wall say to the other wall? I'll meet you at the corner.",
  "Why was the math book sad? It had too many problems.",
  "How does a snowman get around? By riding an 'icicle'.",
  "Why was the computer cold? It had a lot of windows.",
  "Why don't scientists trust atoms? Because they make up everything.",
  "What did the fish say when it hit the wall? Dam.",
  "What do you call a bear with no teeth? A gummy bear.",
  "Why did the bicycle fall over? It was two-tired.",
  "How do you catch a squirrel? Climb a tree and act like a nut.",
  "Why did the golfer bring two pairs of pants? In case he got a hole in one.",
];

export default jokes;
